<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import ActivityList from './components/activity-list.vue'

export default {
  name: 'australiapost-activity-mobile',
  components: {
    BaseAsidePage,
    ActivityList,
  },
  setup() {
    const { pageTitle } = useEditablePage()

    return {
      pageTitle,
    }
  },
}
</script>

<template>
  <base-aside-page
    :title="pageTitle || 'Transaction history'"
    :no-padding="true"
    :back="{
      name: 'home',
    }"
  >
    <activity-list />
  </base-aside-page>
</template>
