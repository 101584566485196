<script>
import { useActivity } from '/~/composables/activity'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import ActivityList from './components/activity-list.vue'

export default {
  name: 'australiapost-activity-desk',
  components: {
    ActivityList,
  },
  setup() {
    const { isActivityEmpty } = useActivity()
    const { pageTitle } = useEditablePage()

    return {
      isActivityEmpty,
      pageTitle,
    }
  },
}
</script>

<template>
  <div
    class="mr-6 flex h-full w-full flex-col overflow-y-auto rounded-t-3xl bg-eonx-neutral-50"
  >
    <h2 v-if="!isActivityEmpty" class="mb-5 mt-[30px] px-12 text-xl">
      {{ pageTitle || 'Transaction history' }}
    </h2>
    <div class="flex h-full w-full overflow-y-auto overflow-x-hidden">
      <activity-list class="w-full px-12 pb-10" />
    </div>
  </div>
</template>
